import axios from "axios";
import { fetchWithTimeout } from "./serviceHelpers";
import { encodeMarkdown } from "../utils/helpers";
import AuthService from "./authService";

const ManageAlertsService = {
  _url: `${process.env.REACT_APP_API_URL || ""}/api`,
  _token(token) {
    if (typeof token !== "undefined") {
      localStorage.setItem("token", token);
      return;
    }
    return localStorage.getItem("token");
  },

  async addAlert(requestObj) {
    let encodedReqObj = {
      ...requestObj,
      content: encodeMarkdown(requestObj.content),
    };

    try {
      let response = await fetchWithTimeout(`${this._url}/alerts/createalert`, {
        method: "POST",
        body: JSON.stringify(encodedReqObj),
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${this._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();
      return true;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        //let data = await response.json();
        //console.log(response);
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },

  async saveAlert(requestObj) {
    let encodedReqObj = {
      ...requestObj,
      content: encodeMarkdown(requestObj.content),
    };

    try {
      let response = await fetchWithTimeout(`${this._url}/alerts/updatealert`, {
        method: "POST",
        body: JSON.stringify(encodedReqObj),
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${this._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();
      return true;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        //let data = await response.json();
        //console.log(response);
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },
  async deleteAlert(alertId) {
    try {
      let response = await fetchWithTimeout(`${this._url}/alerts/deletealert?alertId=${alertId}`, {
        method: "POST",
        body: JSON.stringify(alertId),
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${this._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();
      return true;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        //let data = await response.json();
        //console.log(response);
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },
  async toggleEnableAlert(alertId) {
    try {
      let response = await fetchWithTimeout(`${this._url}/alerts/enablealert?alertId=${alertId}`, {
        method: "POST",
        body: JSON.stringify(alertId),
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${this._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();
      return true;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        //let data = await response.json();
        //console.log(response);
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },

  // async saveGlossary(recordSection, fieldName, fieldDescription, sourceOfData) {
  //   const requestObj = {
  //     recordSection,
  //     fieldName,
  //     fieldDescription,
  //     sourceOfData,
  //   };

  //   try {
  //     let response = await fetchWithTimeout(`${this._url}/glossary/saveglossary`, {
  //       method: "POST",
  //       body: JSON.stringify(requestObj),
  //       headers: {
  //         "content-type": "application/json",
  //         authorization: `Bearer ${this._token()}`,
  //       },
  //     });
  //     if (!response.ok) {
  //       if (response.status === 429) {
  //         throw Error(response.statusText);
  //       } else if (response.status === 403) {
  //         throw Error(response.statusText);
  //       }
  //       let data = await response.json();
  //       throw new Error(data.responseCode);
  //     }
  //     let data = await response.json();
  //     return true;
  //   } catch (err) {
  //     if (err.name === "AbortError") {
  //       throw Error("Request timed out.");
  //     }
  //     if (err.message) {
  //       //let data = await response.json();
  //       //console.log(response);
  //       throw Error(err.message);
  //     }
  //     if (err.statusText) {
  //       throw Error(err.statusText);
  //     }
  //     throw Error("Request Timeout");
  //   }
  // },
  // async updateGlossary(recordSection, fieldName, fieldDescription, sourceOfData, glossaryId) {
  //   const requestObj = {
  //     recordSection,
  //     fieldName,
  //     fieldDescription,
  //     sourceOfData,
  //     glossaryId,
  //   };

  //   try {
  //     let response = await fetchWithTimeout(`${this._url}/glossary/updateglossary`, {
  //       method: "POST",
  //       body: JSON.stringify(requestObj),
  //       headers: {
  //         "content-type": "application/json",
  //         authorization: `Bearer ${this._token()}`,
  //       },
  //     });
  //     if (!response.ok) {
  //       if (response.status === 429) {
  //         throw Error(response.statusText);
  //       } else if (response.status === 403) {
  //         throw Error(response.statusText);
  //       }
  //       let data = await response.json();
  //       throw new Error(data.responseCode);
  //     }
  //     let data = await response.json();
  //     return true;
  //   } catch (err) {
  //     if (err.name === "AbortError") {
  //       throw Error("Request timed out.");
  //     }
  //     if (err.message) {
  //       //let data = await response.json();
  //       //console.log(response);
  //       throw Error(err.message);
  //     }
  //     if (err.statusText) {
  //       throw Error(err.statusText);
  //     }
  //     throw Error("Request Timeout");
  //   }
  // },
  // async deleteGlossary(glossaryId) {
  //   try {
  //     let response = await fetchWithTimeout(`${this._url}/glossary/deleteglossary?glossaryId=${glossaryId}`, {
  //       method: "POST",
  //       //body: JSON.stringify(requestObj),
  //       headers: {
  //         "content-type": "application/json",
  //         authorization: `Bearer ${this._token()}`,
  //       },
  //     });
  //     if (!response.ok) {
  //       if (response.status === 429) {
  //         throw Error(response.statusText);
  //       } else if (response.status === 403) {
  //         throw Error(response.statusText);
  //       }
  //       let data = await response.json();
  //       throw new Error(data.responseCode);
  //     }
  //     let data = await response.json();
  //     return true;
  //   } catch (err) {
  //     if (err.name === "AbortError") {
  //       throw Error("Request timed out.");
  //     }
  //     if (err.message) {
  //       //let data = await response.json();
  //       //console.log(response);
  //       throw Error(err.message);
  //     }
  //     if (err.statusText) {
  //       throw Error(err.statusText);
  //     }
  //     throw Error("Request Timeout");
  //   }
  // },
};
export default ManageAlertsService;
