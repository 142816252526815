import axios from "axios";
import { fetchWithTimeout } from "./serviceHelpers";
import { encodeMarkdown } from "../utils/helpers";
import AuthService from "./authService";

const ManageFaqService = {
  _url: `${process.env.REACT_APP_API_URL || ""}/api`,
  _token(token) {
    if (typeof token !== "undefined") {
      localStorage.setItem("token", token);
      return;
    }
    return localStorage.getItem("token");
  },
  async saveFaq(category, question, answer) {
    const requestObj = {
      category,
      question,
      answer: encodeMarkdown(answer),
    };

    try {
      let response = await fetchWithTimeout(`${this._url}/faq/savefaq`, {
        method: "POST",
        body: JSON.stringify(requestObj),
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${this._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();
      return true;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        //let data = await response.json();
        //console.log(response);
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },
  async updateFaq(faqId, category, question, answer) {
    const requestObj = {
      faqId,
      category,
      question,
      answer: encodeMarkdown(answer),
    };

    try {
      let response = await fetchWithTimeout(`${this._url}/faq/updatefaq`, {
        method: "POST",
        body: JSON.stringify(requestObj),
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${this._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();
      return true;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        //let data = await response.json();
        //console.log(response);
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },

  // updateFaqs is used for drag and drop only
  async updateFaqs(request) {
    try {
      let response = await fetchWithTimeout(`${this._url}/faq/updatefaqs`, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${this._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();
      return true;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        //let data = await response.json();
        //console.log(response);
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },

  async deleteFaq(faqId) {
    try {
      let response = await fetchWithTimeout(`${this._url}/faq/deleteFaq?faqId=${faqId}`, {
        method: "POST",
        //body: JSON.stringify(requestObj),
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${this._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();
      return true;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        //let data = await response.json();
        //console.log(response);
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },
};
export default ManageFaqService;
