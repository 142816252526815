import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation, Navigate, useNavigate, useParams } from "react-router-dom";
import Root from "../components/Root";
import { useAlertsState, useAlertsDispatch } from "../providers/alertsProvider";
import { useAuthState } from "../providers/authProvider";
import { AuthService } from "../services";
const App = (props) => {
  const { onGetActiveAlerts } = useAlertsDispatch();
  const location = useLocation();
  const { isLoggedIn } = useAuthState();
  const [cachedPathname, setCachedPathname] = useState();
  useEffect(() => {
    // let debounce = sessionStorage.getItem("getActiveAlertsDebounce");
    // if (debounce === null) {
    //   sessionStorage.setItem("getActiveAlertsDebounce", true);

    if (AuthService.isLoggedIn()) {
      // check if user exists in localstorage to make sure user is logged In

      if (location.pathname !== "/dashboard") {
        onGetActiveAlerts();
      }
    }

    //   setTimeout(() => {
    //     sessionStorage.removeItem("getActiveAlertsDebounce");
    //   }, 30000);
    // }
  }, [location.pathname]);
  return <Root {...props} />;
};

export default App;
