import React, { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useAlertsState, useAlertsDispatch } from "../../providers/alertsProvider";
import { useAuthState, useAuthsDispatch } from "../../providers/authProvider";
import { deepMergeArrays } from "../../utils/deepMergeArrays";
import { sanitizeActiveAlerts, updateCachedActiveAlerts } from "../../utils/activeAlertsHelper";
import { checkAndRemoveExpiredViewedAlerts } from "../../utils/activeAlertsHelper";

import { date } from "yup";

const css = {
  alertBar: {
    disoplay: "flex",
  },
  snackbarLink: {
    color: "#FFF",
    // borderLeft: "1px solid #fff",
    // borderRadius: "0",
    // lineHeight: "1",
    // verticalAlign: "-.2rem",
    // marginTop: "0.2rem",
    textAlign: "center",
    padding: ".2rem .5rem",
    position: "relative",
    marginLeft: ".5rem",
    textDecoration: "none",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "1.4",
    marginTop: ".15rem",
    "&:hover, &:visited, &:active, &:link": {
      color: "#FFF",
      textDecoration: "none",
    },
    "&::before": {
      display: "block",
      content: "''",
      height: "1rem",
      width: "1px",
      backgroundColor: "#FFF",
      position: "absolute",
      left: "-.5rem",
    },
  },
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert sx={css.alerBar} elevation={6} ref={ref} variant="filled" {...props} />;
});

const ActiveAlertBar = (props) => {
  const { message, severity, duration, belowHeader = false, onClose = null, path, isLoggedIn } = props;
  const [isOpen, setIsOpen] = useState(true);
  const { onGetActiveAlerts, onMarkAlertAsViewed } = useAlertsDispatch();
  const { activeAlertsSuccess, activeAlertsLoading, activeAlertsData = [], alertsListData = [] } = useAlertsState();
  const { loginActiveAlertsData } = useAuthState();
  const [finalAlert, setFinalAlert] = useState({});
  const handleClose = (event, reason) => {
    if (activeAlertsData.length === 1) {
      onMarkAlertAsViewed(activeAlertsData[0]);
    }

    if (reason === "clickaway") {
      //setIsOpen(false);
      return;
    }
    if (onClose !== null) {
      onClose();
    }
    setIsOpen(false);
  };

  const action = (
    <Button
      sx={css.snackbarLink}
      size="small"
      component={Link}
      //to={activeAlertsData.length > 1 ? "/dashboard/releases" : `/alerts/${activeAlertsData[0].alertId}`}
      to={finalAlert.url}
      onClick={handleClose}
    >
      Close and View Details
    </Button>
  );

  const mergeActiveAlerts = (oldActiveAlerts, newActiveAlerts) => {
    // Create a map from the first array
    const map = new Map(oldActiveAlerts.map((obj) => [obj.id, { ...obj }]));

    // Merge objects from the second array into the map
    newActiveAlerts.forEach((obj) => {
      if (map.has(obj.id)) {
        // If the object with the same id exists, merge their data
        map.set(obj.id, {
          ...map.get(obj.id),
          data: {
            ...map.get(obj.id).data,
            ...obj.data,
          },
        });
      } else {
        // If the object with the id does not exist, add it to the map
        map.set(obj.id, obj);
      }
    });

    // Convert the map back to an array
    return Array.from(map.values());
  };

  useEffect(() => {
    // if (isLoggedIn) {
    //   onGetActiveAlerts();
    // }
  }, []);
  useEffect(() => {
    // Check for viewed alerts and remove the expired ones. SHould only run once per session
    // let alreadyCheckedForExpiredViewedAlerts = false;
    // if (sessionStorage.getItem("viewedAlertsCheck") === null) {
    let viewedAlerts = JSON.parse(localStorage.getItem("viewedAlerts")) || [];
    checkAndRemoveExpiredViewedAlerts(viewedAlerts);

    if (activeAlertsData && activeAlertsData.length > 0) {
      let newAlert = sanitizeActiveAlerts(activeAlertsData);
      setFinalAlert(newAlert);
      updateCachedActiveAlerts(activeAlertsData);
      return;
      // sessionStorage.setItem("viewedAlertsCheck", true);
    }
    if (loginActiveAlertsData && loginActiveAlertsData.length > 0) {
      let newAlert = sanitizeActiveAlerts(loginActiveAlertsData);
      setFinalAlert(newAlert);
      updateCachedActiveAlerts(loginActiveAlertsData);
      return;
      // sessionStorage.setItem("viewedAlertsCheck", true);
    }
    // }
    // if (sessionStorage.getItem("activeAlertsCheck") === null) {
    //onGetActiveAlerts();
    //   sessionStorage.setItem("activeAlertsCheck", true);
    // }
  }, [activeAlertsData, loginActiveAlertsData]);
  useEffect(() => {
    if (location.pathname !== "/allreleases") {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [location.pathname]);
  if (Object.keys(finalAlert).length > 0) {
    return (
      <>
        {finalAlert?.severity && finalAlert?.message && finalAlert?.url && (
          <Snackbar
            sx={{
              top: {
                xs: belowHeader ? "104px" : "",
                sm: belowHeader ? "104px" : "",
                md: belowHeader ? "104px" : "",
                lg: belowHeader ? "104px" : "",
                xl: belowHeader ? "104px" : "",
              },
            }}
            open={isOpen}
            //autoHideDuration={finalAlert?.duration || "3000"}
            autoHideDuration={null}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={handleClose}
            data-testid="alert-bar"
          >
            <Alert onClose={handleClose} severity={finalAlert.severity} sx={{ width: "100%" }} action={action}>
              {finalAlert.message}
            </Alert>
          </Snackbar>
        )}
      </>
    );
  } else {
    return <></>;
  }
};

export default ActiveAlertBar;
