import React, { createContext, useContext, useReducer } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { AuthService } from "../services";
import { AlertsService } from "../services";
import { updateCachedActiveAlerts } from "../utils/activeAlertsHelper";
const AuthState = createContext();
const AuthDispatch = createContext();

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const EVENT_TYPES = {
  GET_IS_LOGGED_IN: "get_is_logged_in",
  UPDATE_IS_DETAILED_SEARCH_REQUEST_MODIFIED: "update_is_detailed_search_request_modified",
  UPDATE_DETAILED_SEARCH_REQUEST_SYNTAX: "update_detailed_search_request_syntax",
  UPDATE_DETAILED_SEARCH_KEYPHRASE: "update_detailed_search_keyphrase",
  UPDATE: "update",
  UPDATE_BACK_BUTTON_STATUS: "update_back_button_status",
  UPDATE_SEARCH_REQUEST: "update_search_request",
  UPDATE_FACET_REQUEST: "update_facet_request",
  UPDATE_ADVANCED_SEARCH_KEYWORD_ROWS: "update_advanced_search_keyword_rows",
  LOGIN_SUCCESS: "login_success",
  LOGIN_LOADING: "login_loading",
  LOGIN_ERROR: "login_error",
  SEARCH_LOADING: "search_loading",
  SEARCH_SUCCESS: "search_success",
  LOGIN_ACTIVE_ALERTS_LOADING: "login_acitve_alerts_loading",
  LOGIN_ACTIVE_ALERTS_SUCCESS: "login_acitve_alerts_success",
  LOGIN_ACTIVE_ALERTS_ERROR: "login_acitve_alerts_error",
  GET_FACET_VALUES_LOADING: "get_facet_values_loading",
  GET_FACET_VALUES_SUCCESS: "get_facet_values_success",
  GET_FACET_VALUES_ERROR: "get_facet_values_error",
  GET_USER_REPORTS_LOADING: "get_user_reports_loading",
  GET_USER_REPORTS_SUCCESS: "get_user_reports_success",
  GET_USER_REPORTS_ERROR: "get_user_reports_error",
  GET_ALL_USER_ROLES_LOADING: "get_all_user_roles_loading",
  GET_ALL_USER_ROLES_SUCCESS: "get_all_user_roles_success",
  GET_ALL_USER_ROLES_ERROR: "get_all_user_roles_error",
  GET_IHSI_USER_REPORTS_LOADING: "get_ihsi_user_reports_loading",
  GET_IHSI_USER_REPORTS_SUCCESS: "get_ihsi_user_reports_success",
  GET_IHSI_USER_REPORTS_ERROR: "get_ihsi_user_reports_error",
  SEARCH_ERROR: "search_error",
  USER_SAVE_DATA_LOADING: "user_save_loading",
  USER_SAVE_DATA_SUCCESS: "user_save_success",
  USER_GET_DATA_LOADING: "user_get_data_loading",
  USER_GET_DATA_SUCCESS: "user_get_data_success",
  USER_SAVE_PREFERENCE_LOADING: "user_save_preference_loading",
  USER_SAVE_PREFERENCE_SUCCESS: "user_save_preference_success",
  USER_GET_PREFERENCE_LOADING: "user_get_preference_loading",
  USER_GET_PREFERENCE_SUCCESS: "user_get_preference_success",
  TOKEN_VERIFY_SUCCESS: "token_verify_success",
  TOKEN_VERIFY_FAILED: "token_verify_failed",
  LOGOUT: "logout",
  CLEAR_ERRORS: "clear_errors",
  CLEAR_SEARCH_RESULTS: "clear_search_results",
  ERROR: "error",
};

const EVENTS = {
  [EVENT_TYPES.GET_IS_LOGGED_IN]: (state, event) => {
    const { bool } = event.payload;
    return {
      ...state,
      isLoggedIn: bool !== undefined ? bool : false,
    };
  },
  [EVENT_TYPES.UPDATE_IS_DETAILED_SEARCH_REQUEST_MODIFIED]: (state, event) => {
    const { bool } = event.payload;
    return {
      ...state,
      isDetailedSearchRequestModified: bool !== undefined ? bool : false,
    };
  },
  [EVENT_TYPES.UPDATE_DETAILED_SEARCH_REQUEST_SYNTAX]: (state, event) => {
    const { data } = event.payload;
    return {
      ...state,
      detailedSearchRequestSyntax: data,
    };
  },
  [EVENT_TYPES.UPDATE_DETAILED_SEARCH_KEYPHRASE]: (state, event) => {
    const { data } = event.payload;
    return {
      ...state,
      detailedSearchKeyphrase: data,
    };
  },
  [EVENT_TYPES.UPDATE_BACK_BUTTON_STATUS]: (state, event) => {
    const { status } = event.payload;
    return {
      ...state,
      backButtonStatus: status,
    };
  },
  [EVENT_TYPES.TOKEN_VERIFY_SUCCESS]: (state, event) => {
    return {
      ...state,
      isTokenVerified: true,
    };
  },
  [EVENT_TYPES.TOKEN_VERIFY_FAILED]: (state, event) => {
    return {
      ...state,
      isTokenVerified: false,
    };
  },
  [EVENT_TYPES.UPDATE]: (state, event) => {
    const { name, value } = event.payload;
    return {
      ...state,
      [name]: value,
    };
  },
  [EVENT_TYPES.UPDATE_SEARCH_REQUEST]: (state, event) => {
    const { searchRequest } = event.payload;
    return {
      ...state,
      currentSearchRequest: searchRequest,
    };
  },
  [EVENT_TYPES.UPDATE_FACET_REQUEST]: (state, event) => {
    const { facetRequest } = event.payload;
    return {
      ...state,
      currentFacetRequest: facetRequest,
    };
  },
  [EVENT_TYPES.UPDATE_ADVANCED_SEARCH_KEYWORD_ROWS]: (state, event) => {
    const { keywordRows } = event.payload;
    return {
      ...state,
      advancedSearchKeywordRows: keywordRows,
    };
  },
  [EVENT_TYPES.GET_USER_REPORTS_LOADING]: (state, event) => {
    return {
      ...state,
      getUserReportsLoading: true,
      getUserReportsSuccess: false,
    };
  },
  [EVENT_TYPES.GET_USER_REPORTS_SUCCESS]: (state, event) => {
    const { data } = event.payload;
    return {
      ...state,
      getUserReportsLoading: false,
      getUserReportsSuccess: true,
      userReports: data,
    };
  },
  [EVENT_TYPES.GET_USER_REPORTS_ERROR]: (state, event) => {
    const { error } = event.payload;
    return {
      ...state,
      error,
      getUserReportsLoading: false,
      getUserReportsSuccess: false,
    };
  },
  [EVENT_TYPES.GET_ALL_USER_ROLES_LOADING]: (state, event) => {
    return {
      ...state,
      getAllUserRolesLoading: true,
      getAllUserRolesSuccess: false,
    };
  },
  [EVENT_TYPES.GET_ALL_USER_ROLES_SUCCESS]: (state, event) => {
    const { data } = event.payload;
    return {
      ...state,
      getAllUserRolesLoading: false,
      getAllUserRolesSuccess: true,
      allUserRolesData: data,
    };
  },
  [EVENT_TYPES.GET_ALL_USER_ROLES_ERROR]: (state, event) => {
    const { error } = event.payload;
    return {
      ...state,
      error,
      getAllUserRolesLoading: false,
      getAllUserRolesSuccess: false,
    };
  },
  [EVENT_TYPES.GET_IHSI_USER_REPORTS_LOADING]: (state, event) => {
    return {
      ...state,
      getIhsiUserReportsLoading: true,
      getIhsiUserReportsSuccess: false,
    };
  },
  [EVENT_TYPES.GET_IHSI_USER_REPORTS_SUCCESS]: (state, event) => {
    const { data } = event.payload;
    return {
      ...state,
      getIhsiUserReportsLoading: false,
      getIhsiUserReportsSuccess: true,
      ihsiUserReports: data,
    };
  },
  [EVENT_TYPES.GET_IHSI_USER_REPORTS_ERROR]: (state, event) => {
    const { error } = event.payload;
    return {
      ...state,
      error,
      getIhsiUserReportsLoading: false,
      getIhsiUserReportsSuccess: false,
    };
  },
  [EVENT_TYPES.USER_SAVE_DATA_LOADING]: (state, event) => {
    return {
      ...state,
      userSaveDataLoading: true,
    };
  },
  [EVENT_TYPES.USER_SAVE_DATA_SUCCESS]: (state, event) => {
    return {
      ...state,
      userSaveDataLoading: false,
      userSaveDataSuccess: true,
    };
  },
  [EVENT_TYPES.USER_GET_DATA_LOADING]: (state, event) => {
    return {
      ...state,
      userActivityDataLoading: true,
    };
  },
  [EVENT_TYPES.USER_GET_DATA_SUCCESS]: (state, event) => {
    const { data } = event.payload;
    return {
      ...state,
      userActivityDataLoading: false,
      userActivityDataSuccess: true,
      userActivityData: data,
    };
  },
  [EVENT_TYPES.GET_FACET_VALUES_LOADING]: (state, event) => {
    let thisState = state;
    return {
      ...state,
      getFacetValuesLoading: true,
      getFacetValuesSuccess: false,
    };
  },
  [EVENT_TYPES.GET_FACET_VALUES_SUCCESS]: (state, event) => {
    const { data } = event.payload;
    return {
      ...state,
      getFacetValuesLoading: false,
      getFacetValuesSuccess: true,
      currentFacetData: data,
    };
  },
  [EVENT_TYPES.USER_GET_PREFERENCE_LOADING]: (state, event) => {
    return {
      ...state,
      userGetPreferenceLoading: true,
      userGetPreferenceSuccess: false,
    };
  },
  [EVENT_TYPES.USER_GET_PREFERENCE_SUCCESS]: (state, event) => {
    const { data } = event.payload;
    return {
      ...state,
      userGetPreferenceLoading: false,
      userGetPreferenceSuccess: true,
      userPreferenceData: data,
    };
  },
  [EVENT_TYPES.USER_SAVE_PREFERENCE_LOADING]: (state, event) => {
    return {
      ...state,
      userSavePreferenceLoading: true,
      userSavePreferenceSuccess: false,
    };
  },
  [EVENT_TYPES.USER_SAVE_PREFERENCE_SUCCESS]: (state, event) => {
    return {
      ...state,
      userSavePreferenceLoading: false,
      userSavePreferenceSuccess: true,
    };
  },
  [EVENT_TYPES.SEARCH_LOADING]: (state, event) => {
    return {
      ...state,
      searchLoading: true,
      searchSuccess: false,
    };
  },

  [EVENT_TYPES.LOGIN_SUCCESS]: (state, event) => {
    const { redirectUrl, data } = event.payload;
    return {
      ...state,
      isLoggedIn: true,
      loginLoading: false,
      loginSuccess: true,
      currentUser: data,
      loginRedirectUrl: redirectUrl !== undefined ? redirectUrl : "/",
    };
  },
  [EVENT_TYPES.LOGIN_LOADING]: (state, event) => {
    return {
      ...state,
      isLoggedIn: false,
      loginLoading: true,
      loginSuccess: false,
    };
  },
  [EVENT_TYPES.LOGIN_ERROR]: (state, event) => {
    const { error } = event.payload;
    return {
      ...state,
      isLoggedIn: false,
      loginLoading: false,
      loginSuccess: false,
      loginRedirectUrl: "/",
      error,
    };
  },
  [EVENT_TYPES.SEARCH_SUCCESS]: (state, event) => {
    //console.log("search Filter status: ", event.payload.data.originalRequest.recordStatusFilter);
    return {
      ...state,
      searchSuccess: true,
      searchLoading: false,
      searchData: event.payload.data,
    };
  },
  [EVENT_TYPES.SEARCH_ERROR]: (state, event) => {
    const { error } = event.payload;
    return {
      ...state,
      searchSuccess: false,
      error,
      searchLoading: false,
    };
  },
  [EVENT_TYPES.LOGIN_ACTIVE_ALERTS_LOADING]: (state) => {
    return {
      ...state,
      loginActiveAlertsSuccess: false,
      loginActiveAlertsLoading: true,
    };
  },
  [EVENT_TYPES.LOGIN_ACTIVE_ALERTS_SUCCESS]: (state, event) => {
    const { loginActiveAlertsData } = event.payload;
    return {
      ...state,
      loginActiveAlertsSuccess: true,
      loginActiveAlertsLoading: false,
      loginActiveAlertsData: loginActiveAlertsData,
    };
  },
  [EVENT_TYPES.LOGOUT]: () => {
    return {
      name: "",
      email: "",
      password: "",
      error: "",
      isLoggedIn: false,
    };
  },
  [EVENT_TYPES.ERROR]: (state, event) => {
    const { error } = event.payload;
    return {
      ...state,
      error,
      userActivityDataLoading: false,
      userActivityDataSuccess: false,
      isDetailedSearchRequestModified: false,
      loginActiveAlertsSuccess: false,
      loginActiveAlertsLoading: false,
    };
  },
  [EVENT_TYPES.CLEAR_ERRORS]: (state) => {
    return {
      ...state,
      error: "",
      isDetailedSearchRequestModified: false,
      loginActiveAlertsSuccess: false,
      loginActiveAlertsLoading: false,
    };
  },
  [EVENT_TYPES.CLEAR_SEARCH_RESULTS]: (state, event) => {
    return {
      ...state,
      searchData: {},
      defaultRequest: {
        size: 20,
        from: 0,
        sortOrder: null,
        sortBy: null,
        searchPhrase: "*",
      },
      isDetailedSearchRequestModified: false,
    };
  },
};

let defaultRequest = {
  size: 20,
  from: 0,
  sortOrder: null,
  sortBy: null,
  searchPhrase: "*",
};

let defaultFacetRequest = {
  size: 100,
  from: 0,
  facetName: "compound",
  searchPhrase: "*",
};

let defaultUserData = {
  savedSearches: [
    {
      id: 1,
      size: 20,
      from: 0,
      sortOrder: "asc",
      sortBy: "compound",
      searchPhrase: "*",
    },
  ],
};
let defaultIhsiUserData = {
  savedSearches: [
    {
      id: 1,
      size: 20,
      from: 0,
      sortOrder: "asc",
      sortBy: "compound",
      searchPhrase: "*",
    },
  ],
};
let defaultIhsiUserReportRequest = {
  size: 10000,
  from: 0,
  orderBy: "first_name", // user_id, first_name, last_name, email, created_date, last_login_date, last_login_week, last_login_month, suspended, deleted
  sortOrder: "desc", // asc, desc
  // deleted: null, // true, false
  // suspended: null, // true, false
  // lastLoginMonth: 0,
  // lastLoginWeek: 0,
};

let defaultUserReportRequest = {
  size: 10000,
  from: 0,
  orderBy: "first_name", // user_id, first_name, last_name, email, created_date, last_login_date, last_login_week, last_login_month, suspended, deleted
  sortOrder: "desc", // asc, desc
  // deleted: null, // true, false
  // suspended: null, // true, false
  // lastLoginMonth: 0,
  // lastLoginWeek: 0,
};

let baseRequest = defaultRequest;
//localStorage.getItem("searchRequest") !== null ? JSON.parse(localStorage.getItem("searchRequest")) : defaultRequest;

const INITIAL_STATE = {
  backButtonStatus: null,
  isLoggedIn: AuthService.isLoggedIn() || false,
  loginLoading: false,
  loginSuccess: false,
  loginRedirectUrl: "/",
  currentUser: AuthService.getCurrentUser() || {},
  defaultSearchRequest: baseRequest,
  defaultFacetRequest: defaultFacetRequest,
  defaultUserReportRequest: defaultUserReportRequest,
  isDetailedSearchRequestModified: false,
  detailedSearchRequestSyntax: "",
  detailedSearchKeyphrase: "",
  getUserReportsLoading: false,
  getUserReportsSuccess: false,
  userReports: {},
  getAllUserRolesLoading: false,
  getAllUserRolesSuccess: false,
  getAllUserRolesError: false,
  allUserRolesData: [],
  defaultIhsiUserReportRequest: defaultIhsiUserReportRequest,
  getIhsiUserReportsLoading: false,
  getIhsiUserReportsSuccess: false,
  ihsiUserReports: {},
  searchData: {},
  searchLoading: false,
  searchSuccess: false,
  currentSearchRequest: {},
  currentFacetRequest: {},
  currentFacetData: {},
  getFacetValuesLoading: false,
  getFacetValuesSuccess: false,
  userSaveDataLoading: false,
  userSaveDataSuccess: false,
  userActivityDataLoading: false,
  userActivityDataSuccess: false,
  userActivityData: {},
  userGetPreferenceLoading: false,
  userGetPreferenceSuccess: false,
  userSavePreferenceLoading: false,
  userSavePreferenceSuccess: false,
  userPreferenceData: {},
  advancedSearchKeywordRows: [],
  loginActiveAlertsSuccess: false,
  loginActiveAlertsLoading: false,
  loginActiveAlertsData: [],
  isTokenVerified: true,
  name: "",
  email: "",
  password: "",
  error: "",
};

const AuthReducer = (state, event) => {
  return EVENTS[event.type](state, event) || state;
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

  const handleGetIsLoggedIn = (event) => {
    handleClearErrors();
    const bool = AuthService.isLoggedIn();
    dispatch({ type: EVENT_TYPES.GET_IS_LOGGED_IN, payload: { bool } });
  };

  const handleUpdate = (event) => {
    handleClearErrors();
    const name = event.target.name;
    const value = event.target.value;

    dispatch({ type: EVENT_TYPES.UPDATE, payload: { name, value } });
  };

  const handleIsDetailedSearchRequestModified = (bool) => {
    dispatch({ type: EVENT_TYPES.UPDATE_IS_DETAILED_SEARCH_REQUEST_MODIFIED, payload: { bool } });
  };

  const handleUpdateAdvancedSearchKeywordRows = (keywordRows) => {
    dispatch({ type: EVENT_TYPES.UPDATE_ADVANCED_SEARCH_KEYWORD_ROWS, payload: { keywordRows } });
  };

  const handleUpdateDetailedSearchRequestSyntax = (data) => {
    dispatch({ type: EVENT_TYPES.UPDATE_DETAILED_SEARCH_REQUEST_SYNTAX, payload: { data } });
  };
  const handleUpdateDetailedSearchKeyphrase = (data) => {
    dispatch({ type: EVENT_TYPES.UPDATE_DETAILED_SEARCH_KEYPHRASE, payload: { data } });
  };
  const handleUpdateSearchRequest = (searchRequest) => {
    AuthService.setCurrentSearchRequest(searchRequest);
    dispatch({ type: EVENT_TYPES.UPDATE_SEARCH_REQUEST, payload: { searchRequest } });
  };

  const handleUpdateFacetRequest = (facetRequest) => {
    AuthService.setCurrentFacetRequest(facetRequest);
    dispatch({ type: EVENT_TYPES.UPDATE_FACET_REQUEST, payload: { facetRequest } });
  };
  const compareAndSendUserActivity = (request, activityType) => {
    if (activityType === "search") {
      const prevRequest = state.searchData.originalRequest || "";

      let userRequest = {
        activityType: "search",
        activityValue: request.searchPhrase,
      };

      if (request.searchPhrase && prevRequest.searchPhrase !== request.searchPhrase) {
        handleSaveUserActivity(userRequest);
      }
    }
  };

  const normalizeDate = (reqDate) => {
    let newDate = reqDate;
    if (typeof reqDate === "object") {
      let dateObj = new Date(reqDate).toISOString().substr(0, 10);
      newDate = dateObj;
    }
    return newDate;
  };
  const handleSearch = (request) => {
    if (request?.lastUpdatedDateFrom) {
      request.lastUpdatedDateFrom = normalizeDate(request.lastUpdatedDateFrom);
    }
    if (request?.lastUpdatedDateTo) {
      request.lastUpdatedDateTo = normalizeDate(request.lastUpdatedDateTo);
    }
    if (request?.emaTimelineFrom) {
      request.emaTimelineFrom = normalizeDate(request.emaTimelineFrom);
    }
    if (request?.emaTimelineTo) {
      request.emaTimelineTo = normalizeDate(request.emaTimelineTo);
    }
    //handleUpdateSearchRequest(request);

    compareAndSendUserActivity(request, "search");
    dispatch({ type: EVENT_TYPES.SEARCH_LOADING });
    //AuthService._searchRequest(request);
    AuthService.retrieveSearchResults(request)
      .then((data) => {
        // replace bloated original request with current search request
        let defaultRequest = {
          size: 20,
          from: 0,
          sortOrder: null,
          sortBy: null,
          searchPhrase: "*",
        };
        let currentRequest = {
          ...defaultRequest,
          ...request,
        };
        data.originalRequest =
          currentRequest !== undefined && Object.keys(currentRequest).length > 0
            ? currentRequest
            : data.originalRequest;
        //console.log("Orginal Request:", data);
        dispatch({ type: EVENT_TYPES.SEARCH_SUCCESS, payload: { data } });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.SEARCH_ERROR,
          payload: {
            error: message,
          },
        });
      });
  };

  const handleGetFacetResults = (request) => {
    handleUpdateFacetRequest(request);
    dispatch({ type: EVENT_TYPES.GET_FACET_VALUES_LOADING });
    //AuthService._searchRequest(request);
    AuthService.retrieveFacetResults(request)
      .then((data) => {
        dispatch({ type: EVENT_TYPES.GET_FACET_VALUES_SUCCESS, payload: { data } });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            error: message,
          },
        });
      });
  };
  const handleGetAllUserRoles = (request) => {
    dispatch({ type: EVENT_TYPES.GET_ALL_USER_ROLES_LOADING });
    //AuthService._searchRequest(request);
    AuthService.getAllUserRoles(request)
      .then((data) => {
        dispatch({ type: EVENT_TYPES.GET_ALL_USER_ROLES_SUCCESS, payload: { data } });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            error: message,
          },
        });
      });
  };

  const handleSaveUserActivity = (request, optionalData) => {
    dispatch({ type: EVENT_TYPES.USER_SAVE_DATA_LOADING });
    AuthService.saveUserActivity(request, optionalData)
      .then(() => {
        dispatch({ type: EVENT_TYPES.USER_SAVE_DATA_SUCCESS });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            error: message,
          },
        });
      });
  };

  const handleGetUserActivity = (skip, take) => {
    dispatch({ type: EVENT_TYPES.USER_GET_DATA_LOADING });
    AuthService.getUserActivity(skip, take)
      .then((data) => {
        dispatch({ type: EVENT_TYPES.USER_GET_DATA_SUCCESS, payload: { data } });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            error: message,
          },
        });
      });
  };
  const handleGetIhsiUserReports = (requestObj) => {
    dispatch({ type: EVENT_TYPES.GET_IHSI_USER_REPORTS_LOADING });
    AuthService.getIhsiUserReports(requestObj)
      .then((data) => {
        dispatch({ type: EVENT_TYPES.GET_IHSI_USER_REPORTS_SUCCESS, payload: { data } });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.GET_IHSI_USER_REPORTS_ERROR,
          payload: {
            error: message,
          },
        });
      });
  };

  const handleUpdateBackButtonStatus = (status) => {
    dispatch({ type: EVENT_TYPES.UPDATE_BACK_BUTTON_STATUS, payload: { status } });
  };

  const handleGetUserReports = (requestObj) => {
    dispatch({ type: EVENT_TYPES.GET_USER_REPORTS_LOADING });
    AuthService.getUserReports(requestObj)
      .then((data) => {
        dispatch({ type: EVENT_TYPES.GET_USER_REPORTS_SUCCESS, payload: { data } });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.GET_USER_REPORTS_ERROR,
          payload: {
            error: message,
          },
        });
      });
  };

  const handleGetUserPreference = () => {
    //dispatch({ type: EVENT_TYPES.USER_GET_PREFERENCE_LOADING });
    let data = AuthService.getUserPreferences();
    dispatch({ type: EVENT_TYPES.USER_GET_PREFERENCE_SUCCESS, payload: { data } });
    return data;
  };
  const handleSaveUserPreference = (request) => {
    //dispatch({ type: EVENT_TYPES.USER_SAVE_PREFERENCE_LOADING });
    AuthService.saveUserPreferences(request);
    dispatch({ type: EVENT_TYPES.USER_SAVE_PREFERENCE_SUCCESS });
  };
  const handleLogin = (state, redirectUrl) => {
    const { email, password } = state;
    dispatch({
      type: EVENT_TYPES.LOGIN_LOADING,
    });
    AuthService.login(email, password)
      .then((data) => {
        dispatch({
          type: EVENT_TYPES.LOGIN_SUCCESS,
          payload: {
            redirectUrl: redirectUrl,
            data: data,
          },
        });
        AlertsService.getActiveAlerts()
          .then((loginActiveAlertsData) => {
            setTimeout(() => {
              // Store active alerts in local storage
              updateCachedActiveAlerts(loginActiveAlertsData);
              dispatch({ type: EVENT_TYPES.LOGIN_ACTIVE_ALERTS_SUCCESS, payload: { loginActiveAlertsData } });
              return Promise.resolve();
            }, 500);
          })
          .catch(({ message }) => {
            dispatch({
              type: EVENT_TYPES.ERROR,
              payload: {
                error: message,
              },
            });
          });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.LOGIN_ERROR,
          payload: {
            error: message,
          },
        });
      });
  };
  const handleIsTokenVerified = () => {
    let token = AuthService._token();
    // Keep for testing purposes.
    //token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJuYmYiOjE2NjAzMjAzMTUsImV4cCI6MTY2MDkyNTExNSwiaWF0IjoxNjYwMzIwMzE1fQ.X9k08CytmliFCYseiAoSRszLZdQBAbSczbaiz1afHzs";

    if (token) {
      const decodedJwt = parseJwt(token);
      if (decodedJwt.exp * 1000 < Date.now()) {
        dispatch({ type: EVENT_TYPES.TOKEN_VERIFY_FAILED });
        handleError("Your token has expired. Please log in again to continue.");
        return false;
      } else {
        dispatch({ type: EVENT_TYPES.TOKEN_VERIFY_SUCCESS });
        return true;
      }
    }
  };
  const handleLogout = () => {
    AuthService.logout().then(() => {
      dispatch({ type: EVENT_TYPES.LOGOUT });
      //window.location.reload();
    });
  };

  const handleError = (errorMsg) => {
    dispatch({
      type: EVENT_TYPES.ERROR,
      payload: {
        error: errorMsg,
      },
    });
  };
  const handleClearErrors = () => {
    dispatch({ type: EVENT_TYPES.CLEAR_ERRORS });
  };
  const handleClearSearchResults = () => {
    //localStorage.removeItem("searchRequest");
    dispatch({ type: EVENT_TYPES.CLEAR_SEARCH_RESULTS });
  };

  const events = {
    onGetIsLoggedIn: handleGetIsLoggedIn,
    onUpdate: handleUpdate,
    onError: handleError,
    onLogin: handleLogin,
    onLogout: handleLogout,
    onSearch: handleSearch,
    onClearSearchResults: handleClearSearchResults,
    onUpdateBackButtonStatus: handleUpdateBackButtonStatus,
    onUpdateFacetRequest: handleUpdateFacetRequest,
    onGetFacetResults: handleGetFacetResults,
    onIsTokenVerified: handleIsTokenVerified,
    onUpdateSearchRequest: handleUpdateSearchRequest,
    onSaveUserActivity: handleSaveUserActivity,
    onGetUserActivity: handleGetUserActivity,
    onSaveUserPreference: handleSaveUserPreference,
    onGetUserPreference: handleGetUserPreference,
    onGetUserReports: handleGetUserReports,
    onGetIhsiUserReports: handleGetIhsiUserReports,
    onUpdateAdvancedSearchKeywordRows: handleUpdateAdvancedSearchKeywordRows,
    onIsDetailedSearchRequestModified: handleIsDetailedSearchRequestModified,
    onUpdateDetailedSearchRequestSyntax: handleUpdateDetailedSearchRequestSyntax,
    onUpdateDetailedSearchKeyphrase: handleUpdateDetailedSearchKeyphrase,
    onGetAllUserRoles: handleGetAllUserRoles,
    onClearErrors: handleClearErrors,
  };

  return (
    <AuthState.Provider value={state}>
      <AuthDispatch.Provider value={events}>{children}</AuthDispatch.Provider>
    </AuthState.Provider>
  );
};

const useAuthState = () => {
  const context = useContext(AuthState);

  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }
  // console.log("search Filter status: ", context?.searchData?.originalRequest?.recordStatusFilter);
  return context;
};

const useAuthDispatch = () => {
  const context = useContext(AuthDispatch);

  if (context === undefined) {
    throw new Error("useAuthDispatch must be used within a AuthProvider");
  }

  return context;
};

export { AuthProvider, useAuthState, useAuthDispatch };
